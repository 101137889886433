<template>
  <div class="aboutEducation">
    <h3 class="text-primary">Languages / Frameworks</h3>
    <p class="skillsTitle">Java, Python, C++, Javascript, SQL, Golang</p>
    <p> Node.js, Vue.js, React, TouchDesigner, Processing, Arduino </p>

  </div>
</template>

<script>
export default {
  name: 'AboutSkills',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin:0px;
}

p {
  margin: 0px;
}

.workTitle {
  margin: 30px 0px 0px 0px;
}

.skillsTitle {
  margin-top:14px;
}
</style>
