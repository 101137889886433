<template>
  <div class="aboutExperience">
    <h3 class="text-primary">Experience</h3>
    <p class="workTitle"><strong class="text-primary">Google - Software Engineer </strong>(2016 - 2021)</p>
    <p>Digital Wellbeing - Android</p>
    <p>Kaggle - Web Development</p>
    <p>GCP Dataflow - Data Processing</p>

    <p class="workTitle"><strong class="text-primary">Freelance - Software Engineer </strong>(2021 - present)</p>
    <p>Interactive technological installations</p>

    <p class="workTitle"><strong class="text-primary">Tech Education - Teacher</strong> (2021 - present)</p>
    <p>Self-developed programming course</p>
    <p>Technology class for teenagers</p>
  </div>
</template>

<script>
export default {
  name: 'AboutExperience',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "custom.scss";
  h3 {
    margin:0px;
  }

  p {
    margin: 0px;
  }

  .workTitle {
    margin: 30px 0px 0px 0px;
  }

</style>
